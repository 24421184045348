$(function () {
    const slider = new Swiper('.js-nav', {
        init: false,
        watchOverflow: true,
        spaceBetween: 0,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 3,
                centeredSlides: false,
                // slidesPerGroup: 3,
            },
            500: {
                slidesPerView: 4,
                centeredSlides: false,
            },
            600: {
                slidesPerView: 5,
                centeredSlides: false,
            },
            768: {
                slidesPerView: 7,
                centeredSlides: false,
                // slidesPerGroup: 4,
            },
        },
        // on: {
        //     init: function() {
        //         toggleWidth();
        //     },
        //     resize: function() {
        //         toggleWidth();
        //     },
        // },
    });

slider.init();

// function toggleWidth(e) {
//     const fieldValueWidth = document.querySelector(".nav");
//     fieldValueWidth.innerText = window.innerWidth + " px";
// }
})



